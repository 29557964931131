<template>
    <div>
        <b-sidebar id="add_addpayment" width="50rem" backdrop aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right title="سند قبض" shadow >
        <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>{{$parent.$parent.lang.received_voucher}}</span>
                </div>
                <div @click="hide" id="payHide" style="wodth:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>{{$parent.$parent.lang.close}}</span>
                </div>
            </div>
            <v-divider></v-divider>
            <v-row class="text-center p-3 m-3" :style="`font-size:1.2em;background:red;border:1px solid #000;color:#FFF;direction:`+$parent.$parent.lang.dir">
               المبلغ المتبقي على العميل: {{remaintotal}}
            </v-row>
            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
                    <v-col cols="12" md="6" sm="12">
                        <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label for="input-live">{{$parent.lang.date}}</label>
                                    <b-form-input
                                      v-model="date"
                                      trim
                                      prepend-icon="mdi-calendar"
                                      :rules="fieldRules"
                                      readonly
                                      class="inborder"
                                      v-on="on"
                                      v-bind="attrs"
                                    >{{ date }}</b-form-input>
                            </template>
                            <v-date-picker
                            v-model="date"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" md="6" sm="12" style="display:none;">
                        <label for="input-live">{{$parent.lang.mobile}}</label>
                            <b-form-input
                                v-model="mobile"
                                trim                                
                                class="inborder"
                            >{{ mobile }}</b-form-input>
                    </v-col>
                    <v-col cols="12" md="6" sm="12" style="display:none;">
                        <label for="input-live">{{$parent.lang.full_name}}</label>
                            <b-form-input
                                v-model="full_name"
                                trim                                
                                class="inborder"
                            >{{ full_name }}</b-form-input>
                        
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <label for="input-live">{{$parent.lang.received_amount}}</label>
                            <b-form-input
                                v-model="received_total"
                                trim                                
                                class="inborder"
                                @change="doPaytype"
                            >{{ remaintotal }}</b-form-input>
                    </v-col>
                    
                    <v-col cols="12" md="6" sm="12">
                        <label for="input-live">{{$parent.lang.payment_method}}</label>
                        <b-form-select class="selborder" v-model="paytype" :options="payTypeOp">
                              </b-form-select>
                    </v-col>
                    
                    <v-col cols="12" md="12" sm="12">
                        <label for="input-live">{{$parent.lang.description}}</label>
                        <v-textarea
                            v-model="notes"
                            style="height:150px !important;"
                            class="inborder"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </div>
        </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" id="adVouBTN" @click="addVoucher()" variant="success" class="ma-2" style="width:100px;">{{$parent.$parent.lang.add}}</b-button>
                </div>
            </template>
        </b-sidebar>
        
    </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';

export default{
    props: ['cAmount'],
    data() {
        return {
            received_total:'',
            fieldRules:[
                v => !!v || 'هذا الحقل مطلوب'
            ],
            cashing: false,
            spaning: true,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            mobile: '',
            full_name:'',
            
            notes:'',
            paytype:1,
            paycash:'',
            payspan:'',
            payTypeOp:[],
            cardid: 0,
        }
    },
    computed: {
        remaintotal: function () {
            return this.cAmount;
        },
    },
    created() {
        // setTimeout(() => {
        //     this.received_total = this.cAmount;
        //     this.PaymentDo();
        // },3000)
        this.received_total = this.remaintotal;
        setTimeout(() => {this.getPyamentType()},2000);
    },
    methods: {
        getPyamentType(){
            const post = new FormData();
            post.append('type','getPayTypes');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.payTypeOp = [];
                for(let i=0;i<res.results.data.length;i++){
                    this.payTypeOp.push({
                        text: this.$parent.$parent.lang.langname == 'ar' ? res.results.data[i].type_name : res.results.data[i].type_name_en,
                        value: res.results.data[i].id
                    })
                }
                // this.paytypeOp = t;

                this.paytype = res.results.data[0].id;
            })
        },
        PaymentDo() {
            if(this.paytype == 1){
                this.paycash = this.received_total;
                this.payspan = 0;
            }else if(this.paytype == 2){
                this.payspan = this.received_total;
                this.paycash = 0;
            }else if(this.paytype == 3){
                let pc = this.paycash;
                let ps = this.payspan;
                if(pc != 0){
                    ps = parseFloat(this.received_total) - parseFloat(pc);
                }else if(ps != 0){
                    pc = parseFloat(this.received_total) - parseFloat(ps);
                }
                this.paycash = pc;
                this.payspan = ps;
            }
        },
        checkPay(){
            if(this.paytype == 1){
                this.cashing = false;
                this.spaning = true;
                this.paycash = this.received_total;
                this.payspan = 0;
            }else if(this.paytype == 2){
                this.cashing = true;
                this.spaning = false;
                this.payspan = this.received_total;
                this.paycash = 0;
            }else if(this.paytype == 3){
                this.cashing = false;
                this.spaning = false;
                this.payspan = 0;
                this.paycash = this.received_total;
            }
        },
        doPaytype(){
            if(this.received_total > this.remaintotal){
                this.received_total = this.remaintotal;
            }
            if(this.paytype == 1){
                this.cashing = false;
                this.spaning = true;
                this.paycash = this.received_total;
                this.payspan = 0;
            }else if(this.paytype == 2){
                this.cashing = true;
                this.spaning = false;
                this.payspan = this.received_total;
                this.paycash = 0;
            }else if(this.paytype == 3){
                this.cashing = false;
                this.spaning = false;
                this.payspan = this.received_total;
                this.paycash = 0;
            }
           
        },
        addVoucher(){
            document.getElementById('adVouBTN').disabled = true;
            const post = new FormData();
            post.append("type",'addVouchter');
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[docid]',this.cardid);
            post.append('data[doc_type]',"job_card");
            post.append('data[type]',1);
            post.append('data[mobile]',this.mobile)
            post.append('data[full_name]',this.full_name)
            post.append('data[amount]',this.received_total)
            post.append('data[paytype]',this.paytype)
            post.append('data[payspan]',this.payspan)
            post.append('data[paycash]',this.paycash)
            post.append('data[notes]',this.notes)
            post.append('data[date]',this.date)
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    // console.log("paymentgo",res);
                    this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                    this.modal = false;
                    this.mobile = '';
                    this.full_name ='';
                    this.received_total ='';
                    this.notes = '';
                    if (this.$snotify.notifications.length > 0) {
                        this.$snotify.notifications.forEach(notification => {
                            this.$snotify.remove(notification.id)
                        });
                    }
                    let message = "تمت الاضافة بنجاح";
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    this.$parent.getCard();
                    document.getElementById('payHide').click();
                    document.getElementById('adVouBTN').disabled = false;
                }
            )
        }
    },
}
</script>