<template>
  <div style="margin:0 5px;" class="rowTitle">

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1 backBlack text-center" role="tab" @click="invsummary = !!invsummary">
        {{$parent.$parent.lang.worker}} </b-card-header>
      <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
            <v-simple-table striped hover style="width:100%;">
              <template v-slot:default>
                <thead>
                <tr>
                  <th style="direction:rtl;padding:1px;text-align:center;" class="backBlack">{{$parent.$parent.lang.worker_name}}</th>
                  <th style="direction:rtl;padding:1px;text-align:center;width:100px;" class="backBlack"> {{$parent.$parent.lang.percentage}} %</th>
                  <th style="direction:rtl;padding:1px;text-align:center;width:50px;" class="backBlack" v-if="showbuttoms"></th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(itemo,ind) in lapories"
                    :key="itemo.id"
                >
                  <td>{{ itemo.description }}</td>
                  <td>{{ itemo.price }}</td>
                  <td  v-if="showbuttoms">
                     <v-btn class="ma-1" style="width:70px;height:25px;background:red;color:#FFF" @click="DeleteLabor( ind )">{{$parent.$parent.lang.delete}}</v-btn>
                  </td>
                </tr>
                <tr v-if="showbuttoms">
                  <td>
                    <v-select
                      item-text="text"
                      item-value="value"
                      :items="laborsx"
                      v-model="description"
                      @change="updaPerc(description)"
                    ></v-select>
                    <!-- <b-input v-model="description" id="description" style="font-size: 0.8rem"></b-input> -->
                  </td>
                  <td>
                    <b-input v-model="price" @change="calckMe()" id="price" style="font-size: 0.8rem;width:100%;"></b-input>
                  </td>
                  <td>
                    <v-btn class="ma-1" style="height:25px;background:green;color:#FFF;width:70px" @click="EditLabor( 0 )">{{$parent.$parent.lang.add}}</v-btn>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'CVLaborTable',
  components: {},
  data: () => ({
    showbuttoms: true,
    invsummary:true,
    lapories:[
    ],
    description: '',
    qty: 1,
    price: '',
    total: '',
    cardid: '',
    labors:[],
    flabors:[],
    laborsx:[
      {
        text: 1,
        value: 1,
      }
    ],
    
  }),
  created(){
    // this.getLabor();
    // this.getLaborSelect();
    // setInterval(() => {// console.log("asdfasd" , this.$parent.$parent.showaddinv)},1000);
  },
  computed:{
    iscancel: function(){
      return !this.$parent.showaddinv
    },
    canceled: function(){
      return !this.$parent.showaddinv;
    }
  },
  methods: {
    updaPerc(){
      for(var item in this.laborsx)
      {
        if(this.laborsx[item].value == this.description){
          this.price = this.laborsx[item].percentage;
        }
      }
    },
    calckMe(){
      this.total = parseFloat(this.qty) * parseFloat(this.price);
    },
    getLaborSelect(){
      this.laborsx = [];
      const formdata = new FormData();
      formdata.append('type','getWorkers');
      formdata.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      formdata.append('data[cardid]',this.cardid);
      axios.post(
        this.$SAMCOTEC.r_path, formdata
      ).then(
        (response) => {
          const res = response.data;
         
          if(res.error.number == 200){
            this.flabors = res.results.data;
            for(let i = 0 ; i < this.flabors.length ; i++){
              let cc = {
                text: this.flabors[i].text,
                value: this.flabors[i].value,
                percentage: this.flabors[i].percentage,
              }
              this.laborsx.push(cc);
            }
            
          }
        }
      )
    },
    EditLabor(id){
      const formdata = new FormData();
      formdata.append('type','updateCItem');
      formdata.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      formdata.append('data[cardid]',this.cardid);
      formdata.append('data[type]','labor');
      if(id == 0){
        if(this.description == ''){
          document.getElementById('description').style.background = '#fbff00';
          document.getElementById('description').focus();
          return false;
        }
        
        if(this.qty == 0 || this.qty == ''){
          document.getElementById('qty').style.background = '#fbff00';
          document.getElementById('qty').focus();
          return false;
        }
        
        if(this.price == 0 || this.price == ''){
          document.getElementById('price').style.background = '#fbff00';
          document.getElementById('price').focus();
          return false;
        }

        formdata.append('data[description]',this.description);
        formdata.append('data[qty]',this.qty);
        formdata.append('data[price]',this.price);
        formdata.append('data[total]',this.total);

        axios.post(
          this.$SAMCOTEC.r_path, formdata
        ).then((response) => {
          const res = response.data;
          this.price = 0;
          this.total = 0;
          this.description = '';
          this.getLabor();
        });
        
      }
    },
    DeleteLabor(id){
      const ritem = this.lapories[id];

      const post = new FormData();
      post.append('type','deletedItem');
      post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append('data[id]',ritem.id);
      axios.post(
        this.$SAMCOTEC.r_path, post
      ).then(
        (response) => {
          const res = response.data;
          if(res.error.number == 200){
            this.carworkdes = res.results.data;
            this.getLabor()
          }
        }
      )

    },
    getLabor(){
      const formdata = new FormData();
      formdata.append('type','getCItem');
      
      formdata.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      formdata.append('data[cardid]',this.cardid);
      formdata.append('data[type]','labor');
       axios.post(
          this.$SAMCOTEC.r_path, formdata
        ).then((response) => {
          const res = response.data;
          if(typeof res.results.data != 'undefined')
            this.lapories = res.results.data.results;
        });
    }
  }
}
</script>
<style>
.rowTitle{
  background:#FFF;
  color:#000;
  font-size:14px;
  text-align: right;
  padding:3px 3px 5px 3px;
}
</style>