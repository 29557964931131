<template>
    <div style="width:100%;margin-top:60px !important;" class="mt-10">
        <div style="display:inline-flex;padding:0 5px" v-for="(tab,index) of tabs" :key="index">
            <b-button v-if="tab.class == 'mytab' && tab.style == ''" style="width:140px;background:#000 !important;font-weight:bold;" :class="`btn-sm btn `+tab.smclass" @click='goTo(tab.href)'>{{tab.name}}  <i class="fas fa-arrow-down"></i></b-button>
            <b-button v-else-if="tab.class == 'mytab' && tab.style != ''" :style="`background:#000 !important;width:140px;font-weight:bold;`+tab.style" :class="`btn-sm btn `+tab.smclass" @click='goTo(tab.href)'>{{tab.name}}  <i class="fas fa-arrow-down"></i></b-button>
            <b-button v-else :class="`btn-sm btn `+tab.smclass" :style="`width:140px;background:#eee !important;color:black !important;font-weight:bold;`+tab.style" @click='goTo(tab.href)'>{{tab.name}} </b-button>
        </div>
    </div>
</template>
<script>

export default{
    props: ['tabs'],
    methods: {
        goTo(path){
            this.$router.push({path: path})
        }
    },
    created() {
      //console.log('------',this.tabs);
    },
}
</script>

<style>
.mytab{
  width:100%;
  font-size:.8rem;
  border:0px solid #fff;
}
.mytab2{
  width:100%;
  background:rgb(4, 112, 155) !important;
  font-size:.8rem;
  color:#FFF;
  border:0px solid #fff;
}
.mytab4{
  width:100%;
  font-size:.8rem;
  background:rgb(15, 90, 230) !important;
  border:0px solid #fff;
}
.mytab3{
  width:100%;
  font-size:.8rem;
  background:#198754 !important;
  border:0px solid #fff;
}
.mytab1{
  width:100%;
  font-size:.8rem;
  background:#fe7b33 !important;
  border:0px solid #fff;
}
.mytab5{
  width:100%;
  font-size:.8rem;
  background:#339cfe !important;
  border:0px solid #fff;
}
.mytab6{
  width:100%;
  font-size:.8rem;
  background:#fe3344 !important;
  border:0px solid #fff;
}
.font-small{
  font-size:.8em !important;
  width:115px !important;
}
</style>
