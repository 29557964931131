<template>
    <div class="s-loading">
        
    </div>
</template>
<script>
import axios from 'axios'
export default{
    created() {
        // this.getAllSMS();
        this.chgetCookies();
    },
    methods: {
        chgetCookies(){
            let x = this.$cookies.get(this.$COOKIEPhase);
            const post = new FormData();
            post.append("type","check");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[all]',"justcheck");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                if(typeof res.error === 'undefined')
                  if(this.$route.name != 'Login')
                    this.$router.push({name: "Login"})

              if(typeof res.error.number === 'undefined')
                if(this.$route.name != 'Login')
                  this.$router.push({name: "Login"})


                if(res.error.number == 200){
                    this.$store.state.group_id = res.results.data.group_id;
                    this.connected = true;
                    if(this.$route.name == 'Login' || this.$route.name == 'Loading'){
                    if(this.$store.state.group_id != 4)
                        this.$router.push({path: '/home'});
                    else{
                        this.$router.push({path: '/vendors/vendor-index/'});
                    }
                }
                }else{
                    this.connected = false;
                    if(this.$route.name != 'Login')
                        this.$router.push({name: "Login"})
                }
            })
            
        },
        getAllSMS(){
            const post = new FormData();
            post.append("type","getSMSTemp");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[all]",'123')
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            )
            .then((res) => {
                // console.log(res.data);
                this.$store.state.smsTemp = res.data.results.data;
            })
        },
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
}
</script>
<style>
.s-loading{
    position:fixed;
    top:0;
    left:0;
    bottom: 0;
    right: 0;
    background: #00000055;
}
</style>