<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
        >
        <b-sidebar id="update_car" backdrop width="50rem" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right title="تعديل سيارة" shadow >
        <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>تعديل سيارة</span>
                </div>
                <div @click="hide" id="closeThistoThis" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>اغلاق</span>
                </div>
            </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <v-row>
                <v-col cols="12" md="6" sm="12">
                    <label>اللون</label>
                    <b-form-input class="inborder"
                        v-model="car.color"
                        />
                </v-col>
                <v-col cols="12" md="6" sm="12">
                    <label>رقم اللوحة</label>
                    <b-form-input class="inborder"
                        v-model="car.plate_number"
                        :rules="fieldRules"
                        />
  
                </v-col>
                <v-col cols="12" md="6" sm="12">
                    <label>موديل السيارة</label> 
                    <b-form-input class="inborder"
                        v-model="car.model"
                        :rules="fieldRules"
                        />
                    
                </v-col>
                <v-col cols="12" md="6" sm="12">
                    <label>سنة الصنع</label>
                    <b-form-input class="inborder"
                        v-model="car.made_year"
                        :rules="fieldRules"
                        />
                </v-col>
                
            </v-row>
        </div>
        </template>
        <template #footer="" class="shadow">
        <div class="d-flex text-light align-items-center px-3 py-2">
          <b-button type="button" @click="updateCar()" variant="success" class="ma-2" style="width:100px;">تعديل</b-button>
        </div>
      </template>
        </b-sidebar>
        </v-form>
      </div>
</template>


<script>
import axios from 'axios'
export default{
    data: () => ({
        cardid: '',
        car:{
            car_id: '',
            plate_number: '',
            color: '',
            model: '',
            made_year: '',
        },
        fieldRules:[
            v => !!v || 'هذا الحقل مطلوب',
        ]
    }),
    methods: {
        updateCar(){
            const post = new FormData();
            post.append("type","updatecardcar");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[lang]",this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[cardid]",this.cardid);
            post.append("data[plate_number]",this.car.plate_number);
            post.append("data[model]",this.car.model);
            post.append("data[color]",this.car.color);
            post.append("data[made_year]",this.car.made_year);
            axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            ).then((response) => {
                document.getElementById('closeThistoThis').click();
                this.$parent.updateMe()
            })
        },
        
    },
}
</script>