<template>
    <div>
        <v-form
            ref="form"
            lazy-validation
        >
         <b-sidebar backdrop id="apply_license" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" width="100rem" right title="License Error" shadow >
    <template #default="">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>تفعيل الترخيص</span>
        </div>
        
    </div>
            <v-divider></v-divider>

                <v-row>
                    <v-col cols="12" md="4" sm="12" xs="12" offset-md="4">

                        <v-card style="margin:5px;">
                            
                            <v-card-title style="direction:rtl;border:1px solid #eee; background:#ffca07;font-size:1.2em">تفعيل نظام الفوترة الالكترونية
                            <v-text-field id="lid" type="hidden" v-if="showLID" v-model="license.LID">{{ license.LID }}</v-text-field>
                            </v-card-title>
                            <v-card-text>
                            <v-text-field :rules="mobileRules" v-model="license.LN" type="text" label="رقم الترخيص">{{license.LN}}</v-text-field>
                            <v-text-field :rules="mobileRules" v-model="settings.company_name" type="text" label="اسم الشركة"></v-text-field>
                            <v-text-field v-model="settings.vat_number" type="text" label="الرقم الضريبي"></v-text-field>
                            <v-text-field :rules="mobileRules" v-model="settings.address" type="text" label="العنوان الكامل"></v-text-field>
                            <v-text-field :rules="mobileRules" v-model="settings.mobile" type="phone" label="رقم الاتصال"></v-text-field>
                            <v-text-field :rules="mobileRules" v-model="settings.email" type="email" label="البريد الالكتروني"></v-text-field>
                            <v-text-field :rules="mobileRules" v-model="settings.contactname" type="text" label="جهة الاتصال"></v-text-field>
                            <v-text-field :rules="mobileRules" v-model="settings.contactnumber" type="phone" label="رقم جهة الاتصال"></v-text-field>
                            
                            <b-button @click="registerLicense()" variant="success" type="button" style="font-size: small;width:100%"> التحقق من الترخيص&nbsp;	&nbsp;	<i class="fas fa-sign-in-alt"></i></b-button>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            <vue-snotify></vue-snotify>
    </template>
         </b-sidebar>
         </v-form>
         
    </div>
</template>

<script>
import axios from 'axios'
export default {

    data() {
        return {
            errorAlert: false,
            errorMessage: '',
            showLID: false,
            mobileRules: [
                v => !!v || 'هذا الحقل مطلوب',
            ],
            license: {
                LID: '',
                LN: '',
            },
            settings: {
                company_name: '',
                address: '',
                mobile: '',
                email: '',
                contactname: '',
                contactnumber: '',
                vat_number: '',
            },
            Stoplicense: false
        }
    },
    created() {
        this.checkLicense();
        // const ChLicense = setInterval(() => {
        //     this.checkLicense();
        //     if(this.Stoplicense == true){
        //         clearInterval(ChLicense);
        //     }
        // },15000);
    },
    methods: {
        checkLicense(){
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            axios.get(
                this.$SAMCOTEC.r_path
            ).then( (response) => {
                const res = response.data;
                if(res.error.number == 555){
                    this.license = res.results.data[1].arg;
                    this.settings = res.results.data[0];
                    this.$snotify.info('لتفعيل الترخيص يرجى الاتصال بالمندوب أو اتصل بالرقم 0595907777', {
                        timeout: 25000,
                        showProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true
                    });
                    document.getElementById('apply_license').style.display = "block";
                    this.Stoplicense = true;
                }
            })
        },
        registerLicense() {
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(!this.$refs.form.validate()){
                return false;
            }
            const post = new FormData();
            post.append("type", "LicenseRegistered");
            post.append("data[LID]", this.license.LID);
            post.append("data[LN]", this.license.LN);
            post.append("data[company_name]", this.settings.company_name);
            post.append("data[address]", this.settings.address);
            post.append("data[mobile]", this.settings.mobile);
            post.append("data[email]", this.settings.email);
            post.append("data[contactname]", this.settings.contactname);
            post.append("data[contactnumber]", this.settings.contactnumber);
            post.append("data[vat_number]", this.settings.vat_number);
            axios.post(
                this.$SAMCOTEC.r_path,
                post
            ).then((response) => {
                const res = response.data;
                if(res.error.number == 200){
                    this.$snotify.success(res.error.message, {
                        timeout: 10000,
                        showProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true
                    });
                    setTimeout( function() { location.reload(); },10000)
                }
                else{
                    this.$snotify.info(res.error.message + ' يرجى الاتصال بالمندوب أو الاتصال بالرقم 1595907777', {
                        timeout: 10000,
                        showProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true
                    });
                }
            });
            
        }
    }
}
</script>
