import { render, staticRenderFns } from "./CVSubletsTable.vue?vue&type=template&id=0d04efdd&"
import script from "./CVSubletsTable.vue?vue&type=script&lang=js&"
export * from "./CVSubletsTable.vue?vue&type=script&lang=js&"
import style0 from "./CVSubletsTable.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VCombobox,VForm,VSimpleTable})
